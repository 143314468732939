import React from 'react'
import './about.css'
import Headshot from '../../assets/Headshot.png'

export default function About() {
  return (
    <div id='about'>

      <div className='about-container'>

        <div className='about-content'>
          <div className='headshot'>
            <img src={Headshot} alt='Heather Kruszewski'/>
          </div>
          <div className='about-text-button'>
            <p>I am a Full Stack Software Engineer.</p>
            <p>I gain satisfaction from engaging in continuous learning and personal growth as a software engineer. I find joy in being able to create projects, while exploring the limits of my capabilities and pushing the boundaries of what is achievable through the application of my technical skills.</p>
            <p>Apart from programming, I enjoy reading, playing games and sports (softball and pickleball)!</p>
            <div className='btn-wrapper'>
              <a href='#contact' className='btn btn-primary'>Let's Connect</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
