
import blissfulbites from '../../assets/blissfulbites.png'
import storystacker from '../../assets/storystacker.png'
import appjournal from '../../assets/appjournal.png'


const projects = [

  {
    id: 1,
    image: blissfulbites,
    title: "",
    technical: ["React", "Flask", "CSS"],
    description: "A site that allows you to view, create, edit and delete recipes.",
    github: "https://github.com/heather-tech/BlissfulBites",
    live: "",
    order: 1
  },
  {
    id: 2,
    image: storystacker,
    title: "",
    technical: ["Python", "SQLite", "SQLAlchemy", "Alembic", "Faker"],
    description: "A command-line interface (CLI) tailored specifically for book clubs, to effieciently manage authors and books.",
    github: "https://github.com/heather-tech/StoryStacker",
    live: "",
    order: 2
  },
  {
    id: 3,
    image: appjournal,
    title: "",
    technical: ["React", "JavaScript", "HTML", "CSS"],
    description: "A journal to store all of your future app ideas.",
    github: "https://github.com/heather-tech/APP-JOURNAL",
    live: "",
    order: 3
  },
]

export default projects