import {AiOutlineLinkedin} from "react-icons/ai"
import { FaGithub } from "react-icons/fa";
import {SlNotebook} from "react-icons/sl"

export default function HeaderSocials() {
  return (
    <div className="header-socials">
        <a href="https://www.linkedin.com/in/heather-kruszewski/" target="_blank" rel="noreferrer"><AiOutlineLinkedin /></a>
        <a href="https://github.com/heather-tech" target="_blank" rel="noreferrer"><FaGithub /></a>
        <a href="https://dev.to/heathertech" target="_blank" rel="noreferrer"><SlNotebook /></a>
    </div>
  )
}