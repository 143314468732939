import "./experience.css"
import technicalSkills from "./skills"
import ExperienceCard from "./ExperienceCard"

export default function Experience() {

    const displaySkills = technicalSkills.filter(skill => (skill.displaySkill === true)).sort((a, b) => {
        const skillA = a.order;
        const skillB = b.order;
        if (skillA < skillB) {
            return -1;
        }
        if (skillA > skillB) {
            return 1;
        }
        return 0;
    })

    const sortDisplaySkills = displaySkills.map((technicalSkill) => (
        <ExperienceCard
            key={technicalSkill.id}
            technicalSkill={technicalSkill}
        /> 
    ))


    return (
        <>
    <section id="experience">
        <h2>Technical Skills</h2>
        <h5>Languages, Frameworks & Tools</h5>
        <div className="container experience-container">
            <div className="experience-skills">
                {sortDisplaySkills}
            </div>
        </div>

    </section>
    </>
    )
}