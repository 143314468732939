import { AiFillHtml5, AiOutlineConsoleSql } from "react-icons/ai"
import { DiPython, DiCss3, DiReact, DiPostgresql } from "react-icons/di";
import { SiJavascript, SiSqlalchemy, SiTailwindcss, SiSqlite } from "react-icons/si";
import { FaGithub } from "react-icons/fa";
import { BsGit } from "react-icons/bs";
import { TbFlask } from "react-icons/tb";



const technicalSkills = [
    {
        id: 1,
        icon: <AiFillHtml5 />,
        skill: "HTML",
        category: "frontEnd",
        displaySkill: true,
        order: 7

    },
    {
        id: 2,
        icon: < DiCss3/>,
        skill: "CSS",
        category: "frontEnd",
        displaySkill: true,
        order: 8

    },
    {
        id: 3,
        icon: <DiPython />,
        skill: "Python",
        category: "backEnd",
        displaySkill: true,
        order: 0,

    },
    {
        id: 4,
        icon: <DiReact />,
        skill: "React.js",
        category: "frontEnd",
        displaySkill: true,
        order: 3,

    },
    {
        id: 5,
        icon: <FaGithub /> ,
        skill: "Github",
        category: "devOps",
        displaySkill: true,
        order: 12

    },
    {
        id: 6,
        icon: <SiJavascript /> ,
        skill: "JavaScript",
        category: "frontEnd",
        displaySkill: true,
        order: 2,

    },
    {
        id: 7,
        icon: <AiOutlineConsoleSql /> ,
        skill: "SQL",
        category: "backEnd",
        displaySkill: true,
        order: 9

    },
    {
        id: 8,
        icon: <TbFlask /> ,
        skill: "Flask",
        category: "backEnd",
        displaySkill: true,
        order: 1

    },
    {
        id: 9,
        icon: <BsGit /> ,
        skill: "Git",
        category: "backEnd",
        displaySkill: true,
        order: 11

    },
    {
        id: 10,
        icon: <SiTailwindcss />,
        skill: "Tailwind",
        topSkill: true,
        category: "frontEnd",
        order: 10
    },
    {
        id: 11,
        icon: <SiSqlalchemy />,
        skill: "SQLAlchemy",
        topSkill: true,
        category: "backEnd",
        order: 6
    },
    {
        id: 12,
        icon: <DiPostgresql />,
        skill: "PostgreSQL",
        topSkill: true,
        category: "backEnd",
        order: 5
    },
    {
        id: 13,
        icon: <SiSqlite />,
        skill: "SQLite",
        topSkill: true,
        category: "backEnd",
        order: 4
    },
]

export default technicalSkills
