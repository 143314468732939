import React from 'react'
import './contact.css'
import { AiOutlineLinkedin } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";

export default function Contact() {
  return (
    <section id='contact'>
      <h2>Let's Connect</h2>
      <h5>Contact Me</h5>
      
      <div className='container contact-container'>

        <div className='contact-options'>
          <article className='contact-option'>
            <div className='contact-option-icon-wrapper'>
              <HiOutlineMail className='contact-option-icon' />
            </div>
            <h4>Email</h4>
            <h6>heather.k.tech@gmail.com</h6>
            <div className='btn-wrapper'>
            <a href="mailto:heather.k.tech@gmail.com" target='_blank' rel='noreferrer' className='btn btn-primary'>Send a message</a>
            </div>
          </article>

          <article className='contact-option'>
            <div className='contact-option-icon-wrapper'>
              <AiOutlineLinkedin className='contact-option-icon' />
            </div>
            <h4>Linkedin</h4>
            <h6>Heather Kruszewski</h6>
            <div className='btn-wrapper'>
              <a href='https://www.linkedin.com/in/heather-kruszewski/' target='_blank'rel='noreferrer' className='btn btn-primary'>View Profile</a>
            </div>
          </article>
        </div>
      </div>
      
    </section>
  )
}
