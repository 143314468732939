import React from 'react'

export default function ExperienceCard( { technicalSkill }) {
  const { icon, skill } = technicalSkill
  return (
    <article className='experience-details'>
      <span className='experience-details-icon'>{ icon }</span>
      <p className='experience-content'>{ skill }</p>
    </article>
  )
}
