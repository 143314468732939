import React from 'react'
import './header.css'
import Box from './Box'
import About from '../about/About'
import HeaderSocials from './HeaderSocials'

export default function Header() {
  return (
    <header>
      <div className='container header-container'>
        <h3>Hey there! I'm</h3>
        <h1 className='profile-name'>Heather Kruszewski</h1>
        <h3 className='job-title'>Software Engineer</h3> 
        <Box />
        <section id='about'>
          <h2>Get To Know</h2>
          <h5>About Me</h5>
          <div id='get-to-know-container' className='grid-container'>
            <HeaderSocials />
            <About />
            <a href='/#contact' className='scroll-down'>Scroll Down</a>
          </div>
        </section>
      </div>
    </header>
  )
}

//import React from "react";
// import Typewriter from "typewriter-effect";

// function Type() {
//   return (
//     <Typewriter
//       options={{
//         strings: [
//           "Software Engineer",
//           "Front-End Engineer",
//           "Software Developer",
//           "Open Source Contributor",
//         ],
//         autoStart: true,
//         loop: true,
//         deleteSpeed: 50,
//       }}
//     />
//   );
// }

// export default Type;