import Header from "./components/header/Header"
import Nav from "./components/nav/Nav"
import Experience from "./components/experience/Experience"
import Portfolio from "./components/portfolio/Portfolio"
import Contact from "./components/contact/Contact"
import Footer from "./components/footer/Footer"

function App() {
  return (
    <>
    <Header />
    <Nav />
    <Portfolio />
    <Experience />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
