import React from 'react'
import './footer.css'
import { AiOutlineLinkedin } from "react-icons/ai";
import { FaGithub } from "react-icons/fa";
import {SlNotebook} from "react-icons/sl"



export default function Footer() {
  const today = new Date().getFullYear()

  return (
    <footer>
      <a href='/#' className='footer-logo'>Heather Kruszewski</a>

      <ul className='permalinks'>
        <li><a href='/#'>Home</a></li>
        <li><a href='/#about'>About</a></li>
        <li><a href='/#portfolio'>Portfolio</a></li>
        <li><a href='/#experience'>Skills</a></li>
        <li><a href='/#contact'>Contact</a></li>
      </ul>

      <div className='footer-socials'>
        <a href='https://www.linkedin.com/in/heather-kruszewski/' target='_blank' rel='noreferrer'><AiOutlineLinkedin /></a>
        <a href='https://github.com/heather-tech' target='_blank' rel='noreferrer'><FaGithub /></a>
        <a href='https://dev.to/heathertech' target='_blank' rel='noreferrer'><SlNotebook /></a>
      </div>
      <div className='footer-copyright'>
        © Heather Kruszewski {today}
      </div>
    </footer>
  )
}
